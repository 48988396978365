import React from 'react';

import './MarginForHome.css';
function MarginForHome() {
    return (
        <div className="margin-for-home">
            
        </div>
    )
}

export default MarginForHome;

