import React from 'react';
import Editer from '../EditForNews';

import LogInForm from '../LoginForm/LoginForm';

function EditerPage() {
    return (
        <div>
            <LogInForm />
        </div>
    )
}

export default EditerPage;
